import SiteWrapper from "../../../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../../../assets/images/pagerduty_dxable_og.png";

import MdRenderer from "../../../components/mdrender";
import * as md from "../../../components/mdrender.module.sass";
import TitleBlock from "../../../components/title";

import "./always-on-reliability@gen.scss";

const GeneratedProductFeaturesAlwaysOnReliability = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          信頼性の高い環境 - PagerDuty正規代理店 - 株式会社Digital Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>
        <meta
          property="twitter:description"
          content="配信を保証するAlwaysOnサービス"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="description" content="配信を保証するAlwaysOnサービス" />

        <meta
          property="og:title"
          content="信頼性の高い環境 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta
          property="og:description"
          content="配信を保証するAlwaysOnサービス"
        />
        <meta
          property="og:url"
          content="https://pagerduty.dxable.com/product/features/always-on-reliability/"
        />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/product/features/always-on-reliability/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <TitleBlock
        classNames="md:mb-[80px] mb-[40px]"
        textYellow="Products"
        textGreen="“Always-On”配信保証"
        overview=""
      />

      <div className="aorWrapper">
        <div className="aor-container">
          <div className="center-2 first-section">
            <h2 className="center-title">配信を保証するAlwaysOnサービス</h2>
            <p className="center-para">
              PagerDutyは、信頼性の高いサービスを提供するために複数のデータセンター、ホスティングプロバイダー、通信プロバイダーを使用し、世界中の何千ものお客様へのアラートの配信を常時保証します。
            </p>
          </div>
          <div className="left-1">
            <h2 className="left-title">安定したサービス</h2>
            <p className="left-para">
              当社のサービスは、複数のデータセンターおよびホスティングプロバイダに分散され、利用可能な状態を保ちます。
            </p>
            <div className="body-img">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/aor_1_5893d5a0fe.png"
                alt="安定したサービス"
              />
            </div>
          </div>
          <div className="left-1">
            <h2 className="left-title">配信を保証</h2>
            <p className="left-para">
              すべての通信事業者のプロバイダを体系的にポーリングし、テストすることで、月に500万件以上のアラートを処理し、信頼性の高いSLAを提供します。
            </p>
            <div className="body-img">
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/aor_2_ee5f38f5fb.png"
                alt="配信を保証"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">グローバルサービス</h2>
              <p className="left-para">
                電子メール、電話、SMSプロバイダを含む複数の通信事業者は、180以上の国にサービスを提供できます。
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/aor_3_2a23443ed1.png"
                alt="信頼性の高い環境"
              />
            </div>
          </div>
          <div className="two-col-media-contents left-1">
            <div className="text-part">
              <h2 className="left-title">サービスステータスの透過性</h2>
              <p className="left-para">
                https://status.pagerduty.comのステータスページで透明性を提供しています。
              </p>
            </div>
            <div>
              <img
                src="https://strapi-admin.pagerduty.digitalstacks.net/uploads/aor_4_77ad2efb6f.png"
                alt="サービスステータスの透過性"
              />
            </div>
          </div>
          {/* details */}
          <div className="center-1">
            <h2 className="center-title">常時接続サービスの詳細</h2>
          </div>
          <div className="three-col-without-cta">
            <div className="card">
              <div className="title">
                <p>
                  サービスを中断させない
                  <br />
                  スケール
                </p>
              </div>
              <p>
                当社のサービスは、複数のデータセンターやホスティングプロバイダに分散されているため、ダウンした場合でも利用できるようになります。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>アラート配信SLA</p>
              </div>
              <p>
                自動フェイルオーバーを使用するプロバイダの体系的なポーリングとテストのおかげで、SLAによるアラート配信が保証されます。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>グローバルサービス</p>
              </div>
              <p>
                私たちは複数の通信事業者を使用して、180カ国以上に電子メール、電話、およびSMSアラートを可能にしています。
              </p>
            </div>
            <div className="card">
              <div className="title">
                <p>サービスステータスの透過性</p>
              </div>
              <p>私たちのステータスページは24/7稼働の透明性を提供します。</p>
            </div>
            <div className="card">
              <div className="title">
                <p>Failure Friday</p>
              </div>
              <p>
                信頼性を高めるため、PagerDutyは毎週「Failure
                Friday」を実行して、AWS、Microsoft
                Azure、IBMなどの複数のプロバイダで障害の回復力を手動でテストします
              </p>
            </div>
          </div>
          <div className="conclusion">
            PagerDutyのインシデント解決プラットフォームは、ノイズを削減し、
            <br />
            インシデントをより迅速に解決するのに役立ちます。
          </div>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default GeneratedProductFeaturesAlwaysOnReliability;
